import type { CursorCollection } from "@smakecloud/smake-use";
import axios from "axios";
import type { Customer } from "~/lib/api/types/customers";
import type { Color } from "~/lib/api/types/colors";

export async function list(params?: {
    cursor?: string;
    per_page?: number;
    filter?: Record<string, string>;
    search?: string | null;
}): Promise<CursorCollection<Customer>> {
    return (
        await axios.get<CursorCollection<Customer>>("customers", { params })
    ).data;
}

export async function get(id: number): Promise<Customer> {
    return (await axios.get<Customer>(`customers/${id}`)).data;
}

export async function designLogin(id: number) {
    return (
        await axios.post<{ token_string: string; expires_at: string }>(
            `customers/${id}/designer/login`,
        )
    ).data;
}

export async function listCustomerColors(
    id: number,
    params?: {
        cursor?: string;
        filter?: Record<string, string | number | boolean>;
        search?: string | null;
        per_page?: number;
    },
) {
    return (
        await axios.get<CursorCollection<Color>>(`customers/${id}/colors`, {
            params,
        })
    ).data;
}

export async function activateCustomerColor(
    customerId: number,
    colorIds: { id: number } | { id: number }[],
) {
    return await axios.post(`/customers/${customerId}/colors/activate`, {
        colors: { colorIds },
    });
}

export async function deactivateCustomerColor(
    customerId: number,
    colorIds: { id: number } | { id: number }[],
) {
    return await axios.post(`/customers/${customerId}/colors/deactivate`, {
        colors: { colorIds },
    });
}
